/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 try.glb
*/

import React, {useEffect, useRef} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import {useSelector} from "react-redux"
import {mainSelector} from "./store/mainSlice"

export function Model(props) {
  const group = useRef()
  const leggins = useGLTF('/leggins.glb')
  const { nodes, materials, animations } = useGLTF('/try.glb')
  const { actions } = useAnimations(animations, group)

  const {currentClothing} = useSelector(mainSelector)

  const materialsPants = {
    "red": "Material.001",
    "blue": "Material.007",
    "green": "Material.011",
    "yellow": "Material.004",
    "black": "Material.009"
  }
  const selectedPantsMaterial = materialsPants[currentClothing.pants.color]
  const selectedBolkMaterial = materialsPants[currentClothing.bolk.color]

  useEffect(() => {
    actions['Armature|mixamo.com|Layer0'].play()
  })



  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh name="BaseMesh_Female004" geometry={nodes.BaseMesh_Female004.geometry} material={nodes.BaseMesh_Female004.material} skeleton={nodes.BaseMesh_Female004.skeleton} />
          {
            currentClothing.bolk.isCurrent &&
            <skinnedMesh name="bolk" geometry={nodes.bolk.geometry} material={leggins.materials[selectedBolkMaterial]} skeleton={nodes.bolk.skeleton} />
          }
          {
            currentClothing.pants.isCurrent &&
            <skinnedMesh name="pants" geometry={nodes.pants.geometry} material={leggins.materials[selectedPantsMaterial]} skeleton={nodes.pants.skeleton} />
          }
          {
            props.isWrist &&
            <skinnedMesh name="gloves" geometry={nodes.gloves.geometry} material={materials.f} skeleton={nodes.gloves.skeleton} />
          }
          {
            props.isPants &&
            <skinnedMesh name="pants2" geometry={nodes.pants2.geometry} material={materials['Material.007']} skeleton={nodes.pants2.skeleton} />
          }



        </group>
      </group>
    </group>
  )
}

export default Model

useGLTF.preload('/try.glb')
