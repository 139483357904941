/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 bolk.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import {useSelector} from "react-redux";
import {mainSelector} from "./store/mainSlice";

const materialsPants = {
    "red": "Material.001",
    "blue": "Material.007",
    "green": "Material.011",
    "yellow": "Material.004",
    "black": "Material.009"
}

export function Model(props) {
    const { nodes, materials } = useGLTF('/bolk.glb')
    const {currentClothing} = useSelector(mainSelector)
    const leggins = useGLTF('/leggins.glb')

    const selectedBolkMaterial = materialsPants[currentClothing.bolk.color]

    return (
        <group {...props} dispose={null}>
          <mesh geometry={nodes.bolk.geometry} material={leggins.materials[selectedBolkMaterial]} />
        </group>
    )
}
export default Model

useGLTF.preload('/bolk.glb')
